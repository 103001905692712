@media screen and (max-width: 767px) and (orientation: portrait) {
  .activity-container {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
    transform-origin: left top;
    width: 100%;
    /* height: 100vw; */
    overflow: scroll;
    position: absolute;
    top: 100%;
    left: 0;
  }
  #bootstrap-overrides h1, h2, h3 {
     font-size: 1.5em;
   }
}

@media screen and (max-width: 767px) {
  #fake-div {
    height: 200px
  }
}

/* .draggableItem :hover {
  background: #4da3ff;
  color: 'black';
} */