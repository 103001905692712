body {
  font-family: 'Open Sans', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}


.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

nav {
  background-color: #008080;
}

.imgForm {
  max-width: 200px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/*
---------------------- Styles for Home Page --------
*/

.header {
   /* height: 70vh; */
  /* color: white; */
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}

.call2actionBtn {
  background-color: #FF001F;
  color: white;
}

.services {
  background-color: #787060;
  color: white;
}

.how-img {
  height: 200px;
}

.circle {
  font-size: 3em;
}

.how {
  background-color: #e0d8d8;
}

