.canvas-container {
  position: relative;
  /* min-width: 400px;
  min-height: 225px; */
  margin: 0 auto;
}

#water-container {
  width: 100%;
  height: auto;
  z-index: 0;
}

.label-container {
  position: relative;
  max-width: 300px;
  min-width: 200px;
  max-height: 90px;
  min-height: 60px;
  margin: 0 auto;
}

#label-container {
  width: 100%;
  height: auto;
  z-index: 0;
}