/* .fixed_header {
  width: 100%;
  height: 100%;
  table-layout: auto;
  border-collapse: collapse;
} */

.tableDiv {
 overflow-x:auto;
}

/* .fixed_header tbody{
display:block;
width: 100%;
overflow: auto;
overflow-x: scroll;
height: 100px;
} */

/* .fixed_header thead { */
 /* display: block; */
 /* height: 100px;
 overflow: hidden;
 background: #008080;
 color:#fff;
 vertical-align: top;
} */

/* .fixed_header tr {
  display: block;
 }


.fixed_header th, .fixed_header .table-heading {
padding: 5px;
text-align: left;
width: 300px;
}

.fixed_header td {
  padding: 5px;
  text-align: left;
  width: 200px;
  } */